import React from 'react';
import partner1 from '../images/partner1.jpg';
import partner2 from '../images/partner2.png';

const Partners = () => {
	return (
		<div className="p-8 pt-12 lg:p-20 flex justify-center items-center w-full">
			<div className=" ">
				<h1 className="text-3xl lg:text-6xl text-center text-brownn font-semibold">Our Partners</h1>
				<div className="flex flex-col lg:flex-row justify-around items-center p-12 ">
					<img className="lg:w-1/4 h-1/2" src={partner1} alt="Partner 1"/>
					<img className="lg:w-1/4 h-1/2" src={partner2} alt="Partner 2"/>
				</div>
			</div>
		</div>
	)
}

export default Partners;
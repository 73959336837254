import React, {useState, useEffect} from 'react';
import Herosection from '../components/Herosection';
import About from '../components/About';
import Services from '../components/Services';
import Offer from '../components/Offer';
import Partners from '../components/Partners';
import Booking from "../components/Booking";

const Home = () => {
	const [isVisible, setIsVisible] = useState(false);
	const handleScroll = () => {
	    if (window.pageYOffset > 300) {
	        setIsVisible(true);
	    } else {
	        setIsVisible(false);
	    }
    };

    const scrollToTop = () => {
	    window.scrollTo({
			top: 0,
			behavior: 'smooth',
	    });
    };

    useEffect(() => {
	    window.addEventListener('scroll', handleScroll);
	    return () => {
	        window.removeEventListener('scroll', handleScroll);
	    };
    }, []);
	return (
		<div>
			<Herosection/>
			<About/>
			<Services/>
			<Offer/>
			<Partners/>
			<Booking/>
			<div
			    onClick={scrollToTop}
			    className={isVisible ? "bg-brownn p-2 text-center rounded-full w-14 h-14 hover:bg-black text-white fixed bottom-20 right-8 z-10000" : "hidden"}>
			    <i className="fas fa-angle-up text-4xl "></i>
			</div>
		</div>
	)
}

export default Home;
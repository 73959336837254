import React from 'react';
import passengertravel from '../images/passengertravel.png';
import passengertravell from '../images/passengertravell.png';
import {faPlaneDeparture, faCircleQuestion, faHandshake} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Services = () => {
    return (<section id="services" className="p-8 pb-20 md:p-28 pt-0 w-full flex justify-center items-center">
        <div className="flex flex-col gap-10 lg:gap-20">
            <h1 className="font-bold text-open-sans text-3xl md:text-5xl tracking-widest text-brownn text-center">Services</h1>
            <div className="grow flex flex-col lg:flex-row justify-start lg:justify-between gap-10 items-center">
                <div
                    className="bg-white rounded-2xl p-6 lg:p-10 w-full lg:w-[25rem] lg:h-[38rem] flex flex-col justify-start items-center gap-12 drop-shadow-xl">
                    <FontAwesomeIcon icon={faPlaneDeparture} size="3x" style={{color: "#561717"}}/>
                    <h3 className="text-xl lg:text-2xl font-bold p-2">Air Charter and Emergency Medical
                        Services</h3>
                    <div className="text-base lg:text-lg text-left flex flex-col gap-4">
                        <p className="font-normal text-lg md:text-xl pb-4 text-faint-black">At <span
                            className="text-brownn font-bold">Dzuels Logistics</span>, we
                            connect the private premium traveller with the safest, closest
                            and
                            most reliable aircraft operator to complete a flight request to or from a major city or
                            remote location</p>
                    </div>
                </div>
                <div className="grow flex flex-col lg:flex-row justify-start items-center">
                    <div
                        className="bg-white rounded-2xl p-6 pt-10 pb-10 lg:p-10 w-full lg:w-[25rem] lg:h-[38rem] flex flex-col justify-start items-center gap-12 drop-shadow-xl">
                        <FontAwesomeIcon icon={faCircleQuestion} size="3x" style={{color: "#561717"}}
                                         className="align-top"/>
                        <h3 className="text-xl lg:text-2xl font-bold p-2 lg:pb-10">Aviation Advisory</h3>
                        <div className="text-base lg:text-lg text-left flex flex-col gap-4">
                            <p className="font-normal text-lg md:text-xl pb-4 text-faint-black">We offer Aviation
                                Advisory on Training and Logistics</p>
                        </div>
                    </div>
                </div>
                <div className="grow flex flex-col lg:flex-row justify-start items-center">
                    <div
                        className="bg-white rounded-2xl p-6 pt-10 lg:p-10 w-full lg:w-[25rem] lg:h-[38rem] flex flex-col justify-start items-center gap-12 drop-shadow-xl ">
                        <FontAwesomeIcon icon={faHandshake} size="3x" style={{color: "#561717"}}/>
                        <h3 className="text-xl lg:text-2xl font-bold p-2">Aircraft Acquisition, Sale and
                            Management</h3>
                        <div className="text-base lg:text-lg text-left flex flex-col gap-4">
                            <p className="font-normal text-lg md:text-xl text-faint-black ">We create and manage the
                                overall transaction structure of aircraft acquisition and
                                sale,
                                delivery, release to service and aircraft management through experienced sales force
                                with international reach, network of industry technical professionals, access to
                                maintenance organizations, with high ethical, operational and safety standards</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default Services;
import React from 'react';

const Footer = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({top: section.offsetTop, behavior: 'smooth'});
    };

    return (<div id="footer"
                 className="bg-cream p-6 pt-16 pb-16 md:p-8 md:pt-12 md:pb-12 lg:p-6 lg:pt-12 lg:pb-12 text-black w-full">
        <div
            className="flex flex-col-reverse gap-4 md:gap-24 lg:gap-0 lg:flex-row justify-between items-center text-open-sans w-full">
            <div className="p-3 text-center lg:text-left w-fit">
                <h3 className="text-3xl w-full tracking-widest lg:text-5xl font-bold lg:font-semibold pb-3 text-brownn">Dzuels
                    Logistics</h3>
                <p className="text-base font-normal pb-3 italic ">Providing safe and reliable aircraft charter
                    services</p>
                <p className="text-md font-normal pb-3 text-faint-black mt-10 lg:mt-16">DzuelsLogistics &copy; 2023</p>
            </div>
            <div className="flex justify-between items-start gap-16 lg:gap-48 ">
                <div className="">
                    <h3 className="text-2xl md:text-4xl font-semibold pb-4">Contact</h3>
                    <p className="pb-4 text-sm md:text-base"><i className="fa-solid fa-location-dot pr-3"></i><span>17, Ibukunle street (TY Workspace),<br/><span
                        className="font-bold lg:pl-8"> Yaba, Lagos-Nigeria</span></span></p>
                    <a href="tel:+2347015816754"><i className="fa-solid fa-phone pr-3"></i><span
                        className="hover:text-brownn">(+234)
                        7015816754</span></a>
                    <br/>
                    <a href="tel:+2348139523789" className="hover:text-brownn"><i className="fa-solid fa-phone pr-3"
                                                                                  style={{color: '#ffffff'}}></i>(+234)
                        8139523789</a>
                    <br/>
                    <a href="mailto:admin@dzuelslogistics.com"
                       className="text-sm md:text-base font-normal pt-4 hover:text-brownn"><i
                        className="fa-solid fa-envelope pr-2"></i>admin@dzuelslogistics.com</a>
                    <div className="pt-8">
                        <a href="https://www.facebook.com/DZUELSLOGISTICS" target="_blank"
                           rel="noreferrer"><i
                            className="fa-brands fa-facebook-f bg-brownn rounded-full p-2 pl-4 pr-4 text-xl mr-4 "
                            style={{color: '#ffffff'}}></i></a>
                        <a href="https://www.linkedin.com/in/dzuels-logistics-121156231/" target="_blank"
                           rel="noreferrer"><i
                            className="fa-brands fa-linkedin-in bg-brownn rounded-full p-2 pl-3 pr-3 text-xl mr-4 "
                            style={{color: '#ffffff'}}></i></a>
                        <a href="https://www.instagram.com/dzuelslogistics/" target="_blank" rel="noreferrer"><i
                            className="fa-brands fa-instagram bg-brownn rounded-full p-2 pl-3 pr-3 text-xl mr-4 mt-3 lg:mt-0"
                            style={{color: '#ffffff'}}></i></a>
                        <a href="https://twitter.com/DzuelsLogistics" target="_blank" rel="noreferrer"><i
                            className="fa-brands fa-twitter bg-brownn rounded-full p-2 pl-3 pr-3 text-xl mt-3 lg:mt-0 "
                            style={{color: '#ffffff'}}></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Footer;
import React, {useState} from 'react';
import {motion} from "framer-motion"
import logoo from '../images/logo.png';


const Navbar = () => {
    const [closeMobile, setCloseMobile] = useState(false);
    const variants = {
        open: {opacity: 1, x: 0}, closed: {opacity: 0, x: "-100%"},
    }
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({top: section.offsetTop, behavior: 'smooth'});
        setCloseMobile(false);
    };


    return (<nav className="flex justify-center w-screen h-24 md:h-24 sticky top-0 p-8 bg-white z-50">
        <div
            className="lg:hidden w-full h-full flex flex-col items-center p-6 ml-10 mr-10"
        >
            <div className="w-full flex justify-between items-center">
                <div className="w-full flex justify-between items-center">
                    <img className="absolute left-8" src={logoo} width={50} height={50} alt="logo"/>
                    {!closeMobile && <div className="mr-8 absolute right-0 lg:hidden" onClick={() => {
                        setCloseMobile(true)
                    }}><i className="fa-solid fa-bars" style={{fontSize: '27px'}}></i></div>}
                </div>
                <div className="absolute right-0 lg:hidden">
                    {closeMobile && <div className="mr-8" onClick={() => {
                        setCloseMobile(false)
                    }}><i className="fas fa-times" style={{fontSize: '27px'}}></i></div>}
                </div>
            </div>
            <motion.div
                animate={closeMobile ? "open" : "closed"}
                variants={variants}
                className="lg:hidden flex flex-col justify-center w-full items-center  bg-white mt-8 fixed p-2 "
            >
                <ul className="flex flex-col items-start bg-white w-full gap-10 p-10 pt-14">
                    <li
                        onClick={() => {
                            setCloseMobile(false);
                            scrollToSection('home')
                        }}
                        className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                    >HOME
                    </li>
                    <li
                        onClick={() => scrollToSection('aboutus')}
                        className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                    >ABOUT
                    </li>
                    <li
                        onClick={() => scrollToSection('services')}
                        className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                    >SERVICE
                    </li>
                    <li
                        onClick={() => scrollToSection('offers')}
                        className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                    >OFFERS
                    </li>
                    <li
                        className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                    >CONTACT US
                    </li>
                </ul>
            </motion.div>
        </div>
        <div className="hidden lg:flex w-full h-full justify-between items-center p-6 ml-10 mr-10">
            <div className="">
                <img src={logoo} width={50} height={50} alt="logo"/>
            </div>
            <ul className=" flex flex-row gap-10">
                <li
                    onClick={() => scrollToSection('home')}
                    className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn focus:text-black cursor-pointer"
                >HOME
                </li>
                <li
                    onClick={() => scrollToSection('aboutus')}
                    className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                >ABOUT
                </li>
                <li
                    onClick={() => scrollToSection('services')}
                    className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                >SERVICE
                </li>
                <li
                    onClick={() => scrollToSection('offers')}
                    className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                >OFFERS
                </li>
                <li
                    onClick={() => scrollToSection('footer')}
                    className="text-open-sans text-brownn font-bold text-lg hover:border-b-4 hover:border-brownn hover:text-black active:border-b-4 active:border-brownn active:text-black cursor-pointer"
                >CONTACT US
                </li>

            </ul>
        </div>
    </nav>)
}

export default Navbar;
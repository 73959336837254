import React from 'react';

const Booking = () => {
    return (<section id="servicespage"
                     className="p-10 pb-16 pt-12 md:p-36 lg:p-20 mb-20 lg:mb-24 bg-brownn flex justify-center items-center">
        <div className=" w-full">
            <h3 className="text-lg text-center lg:text-2xl text-white p-6 text-open-sans pb-8 mb-12">
                To ensure we provide you with the best possible experience, kindly select the number of
                individuals who will be benefiting from our service
            </h3>
            <div className="flex flex-col lg:flex-row justify-around items-center gap-16 lg:gap-0">
                <div
                    className="bg-white rounded-2xl p-6 pt-10 pb-10 lg:p-10 w-full lg:w-[25rem] lg:h-[15rem] flex flex-col justify-center items-center gap-12">
                    <h3 className="text-2xl lg:text-3xl font-bold p-2">1-8 Persons</h3>
                    {/*<div className="text-base lg:text-lg text-left flex flex-col gap-4">
						</div>*/}
                    <button
                        onClick={() => makeBooking(1)}
                        className="w-44 h-12 rounded-3xl bg-brownn text-white hover:text-brownn hover:bg-white hover:border-2 hover:border-brownn font-semibold">CHARTER
                        NOW
                    </button>
                </div>
                <div
                    className="bg-white rounded-2xl p-6 pt-10 pb-10 lg:p-10 lg:pb-10 w-full lg:w-[25rem] lg:h-[15rem] flex flex-col justify-center items-center gap-12">
                    <h3 className="text-2xl lg:text-3xl font-bold p-2">9-13 Persons</h3>

                    <button
                        onClick={() => makeBooking(9)}
                        className="w-44 h-12 rounded-3xl bg-brownn text-white hover:text-brownn hover:bg-white hover:border-2 hover:border-brownn font-semibold">CHARTER
                        NOW
                    </button>
                </div>
            </div>
        </div>
    </section>)
}

function makeBooking(num) {
    if (num === 1) window.open('mailto:admin@dzuelslogistics.com?subject=Booking%20for%201-8%20persons&body=How%20can%20we%20help%20you%20today%3F'); else window.open('mailto:admin@dzuelslogistics.com?subject=Booking%20for%209-13%20persons&body=How%20can%20we%20help%20you%20today%3F')
}

export default Booking;
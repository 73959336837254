import React from 'react';
import jetinterior from '../images/jet-interior-1.jpg';
import nigeriaimage from '../images/nigeria-image.jpg';
import vacation from '../images/vacation-pic.jpg';
import wedding from '../images/wedding-candidate-1.jpg';


const Offers = () => {
    return (<section id="offers" className="p-8 pb-20 md:p-28 pt-0 w-full flex justify-center items-center">
        <div className="flex flex-col gap-10 lg:gap-20">
            <h1 className="font-bold text-open-sans text-3xl md:text-5xl tracking-widest text-brownn text-center">Offers</h1>
            <div className="rounded-2xl lg:pr-8 shadow-lg flex items-start flex-col lg:flex-row">
                <div className="w-full h-full lg:w-1/2">
                    <img className="w-full rounded-t-2xl lg:rounded-r-none lg:rounded-l-2xl" src={vacation}
                         alt="beach"/>
                </div>
                <div
                    className="w-full lg:w-1/2 p-8 lg:p-0 lg:mt-auto lg:mb-auto flex flex-col items-center lg:items-start lg:pl-20">
                    <h2 className="font-semibold text-2xl md:text-4xl pb-4 text-brownn">Holiday
                        package</h2>
                    <div>
                        <p className="font-normal text-lg md:text-xl pb-4 text-faint-black">
                            Let <span className="text-brownn font-bold">Dzuels Logistics</span> plan a one of a kind
                            vacation for
                            you to these
                            visa free and visa on arrival countries:
                        </p>
                        <ol className="list-disc">
                            <li className="text-base md:text-lg text-faint-black">Barbados</li>
                            <li className="text-base md:text-lg text-faint-black">Seychelles</li>
                            <li className="text-base md:text-lg text-faint-black">Mauritius</li>
                            <li className="text-base md:text-lg text-faint-black">Rwanda</li>
                            <li className="text-base md:text-lg text-faint-black">Maldives</li>
                            <li className="text-base md:text-lg text-faint-black">Namibia</li>
                            <li className="text-base md:text-lg text-faint-black">Egypt</li>
                            <li className="text-base md:text-lg text-faint-black">Zanzibar</li>
                        </ol>
                        <br/>
                        <p className="font-normal text-lg text-faint-black italic">
                            Is Your preferred country not on this list? Give us a call
                            and we can discuss a wider range of options.
                        </p>
                    </div>
                </div>
            </div>
            <div className="rounded-2xl lg:pl-8 shadow-lg flex flex-col lg:flex-row-reverse mt-8">
                <div className="w-full lg:w-1/2">
                    <img className="w-full rounded-t-2xl lg:rounded-l-none lg:rounded-r-2xl" src={nigeriaimage}
                         alt="services 2"/>
                </div>
                <div
                    className="w-full lg:w-1/2 p-8 lg:mt-auto lg:mb-auto flex flex-col items-center lg:items-start lg:pl-20">
                    <h2 className="font-semibold text-2xl md:text-4xl pb-4 text-brownn">Visit Nigeria</h2>
                    <p className="font-normal text-lg md:text-xl pb-4 text-faint-black">
                        Do you want to visit Nigeria? Let’s help make it a breeze
                        for you by providing a jet, VIP transport, security detail and
                        a chaperone throughout your visit.
                    </p>
                </div>
            </div>
            <div className="rounded-2xl lg:pr-8 shadow-lg flex flex-col lg:flex-row lg:items-start mt-8">
                <div className="w-full lg:w-1/2">
                    <img className="w-full rounded-t-2xl lg:rounded-r-none lg:rounded-l-2xl" src={jetinterior}
                         alt="Private Jet Interior"/>
                </div>
                <div
                    className="w-full lg:w-1/2 p-8 lg:mt-auto lg:mb-auto flex flex-col items-center lg:items-start lg:pl-20">
                    <h2 className="font-semibold text-xl md:text-4xl pb-4 text-brownn">Entertainment Tours</h2>
                    <p className="font-normal text-lg md:text-xl pb-4 text-faint-black">
                        Are you a performing artist? Would you like to add Nigeria to the list of countries for your
                        tour? Or are you an entertainment industry executive? Do you need a reliable
                        means of transportation for your talent or team within or
                        outside Nigeria? Let’s set it up
                    </p>
                </div>
            </div>
            <div className="rounded-2xl lg:pl-8 shadow-lg flex flex-col lg:flex-row-reverse mt-8 lg:items-center">
                <div className="w-full lg:w-1/2">
                    <img className="w-full rounded-t-2xl lg:rounded-l-none lg:rounded-r-2xl " src={wedding}
                         alt="Wedding Celebration"/>
                </div>
                <div
                    className="w-full lg:w-1/2 p-8 lg:mt-auto lg:mb-auto flex flex-col items-center lg:items-start lg:pl-20">
                    <h2 className="font-semibold text-2xl md:text-4xl pb-4 text-brownn">Destination Celebrations</h2>
                    <p className="font-normal text-lg md:text-xl pb-4 text-faint-black">
                        Thinking of a birthday, wedding, personal anniversary or corporate convention away from home?
                        Let’s help you plan your celebration at the destination of your choice. You imagine it, We bring
                        it to life. All you have to do is show up.
                    </p>
                </div>
            </div>
        </div>
    </section>)
}

export default Offers;
import React from 'react';
import logo from '../images/logo.png';

const About = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({top: section.offsetTop, behavior: 'smooth'});
    };
    return (<section id="aboutus" className="p-8 pb-20 md:p-28 md:pb-8 w-full flex justify-center items-center">
        <div className="rounded-2xl lg:pl-8 shadow-lg flex flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-1/2 p-8 lg:mt-auto lg:mb-auto flex flex-col items-center lg:items-start">
                <h2 className="p-2 pb-2 lg:pb-6 text-center lg:text-left font-bold text-open-sans text-2xl text-brownn md:text-5xl tracking-widest">About
                    Us</h2>
                <p className="p-2 lg:pr-10 lg:pb-6 text-center lg:text-left font-normal text-open-sans text-lg md:text-xl text-faint-black">
                    <span className="text-brownn font-bold">Dzuels Logistics</span> provides air logistics services including aircraft acquisition and management, safe
                    and reliable aircraft charter services across Africa and other continents with Nigeria as a hub, as
                    well as holiday packages and air logistics for event planning
                </p>
                <button
                    onClick={() => scrollToSection('footer')}
                    className="lg:ml-2 p-2 w-36 h-12 bg-brownn text-white hover:bg-white hover:text-brownn hover:border-2 hover:border-brownn rounded-3xl">Contact
                    us
                </button>
            </div>
            <div className=" w-full lg:w-1/2  pt-4 pb-4  hero container max-w-screen-lg mx-auto">
                <img
                    className="rounded-t-2xl lg:rounded-tl-none lg:rounded-r-2xl w-[10rem] lg:w-[20rem] mx-auto my-auto"
                    src={logo} alt="Dzuels Logo"/>
            </div>
        </div>
    </section>)
}

export default About;
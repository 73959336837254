import React from 'react';

const Herosection = () => {
	return (
		<section id = "home" className="bg-hero-image h-screen bg-no-repeat bg-cover flex justify-start items-center p-4 pl-8 md:p-8 md:pl-12">
			<div>
			    <div className=" p-2 md:p-6 mb-6">
					<h1 className="font-semibold text-brownn text-5xl md:text-8xl text-open-sans italic tracking-widest">Dzuels</h1>
					<h1 className="font-bold text-brownn text-5xl md:text-8xl text-open-sans italic tracking-widest">Logistics</h1>
				</div >
				<div className="pl-2 md:pl-6 font-bold">
					<p className="font-normal text-blackk text-open-sans text-lg md:text-2xl uppercase">bringing business aviation to your doorstep with:</p>
				</div>
				<div className="p-2 md:p-6 pt-3 font-bold">
					<p className="font-normal text-blackk text-open-sans text-sm md:text-xl uppercase">+ Aircraft Acquisition & Management </p>
                    <p className="font-normal text-blackk text-open-sans text-sm md:text-xl uppercase">+ Safe & reliable aircraft charters </p>
                    <p className="font-normal text-blackk text-open-sans text-sm md:text-xl uppercase">+ Aviation logistics Advisory </p>
                    <p className="font-normal text-blackk text-open-sans text-sm md:text-xl uppercase">+ Aviation training Advisory</p>
				</div>
			</div>
		</section>
	)
}

export default Herosection;